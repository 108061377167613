import { css } from '@emotion/react';
import styled from '@emotion/styled';

const goodsBoxWidth = 279;
const goodsBoxHeight = 477;

export const goodsBoxCss = css`
  width: ${goodsBoxWidth}px;
  height: ${goodsBoxHeight}px;
  border-radius: 7px;
  margin: 10px;

  background: rgba(1, 252, 181, 0.2); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    rgba(1, 252, 181, 0.2),
    rgba(175, 81, 255, 0.2)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    rgba(1, 252, 181, 0.2),
    rgba(175, 81, 255, 0.2)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;
