import { MODAL_STYLED } from '../../../../../components/Ui/Modal/lib/modal_css';
import { colors } from '../../../../../Styles/ui';

export const GOODS_MODAL_STYLE = {
  ...MODAL_STYLED,
  padding: '20px',
  width: 'auto',
};

export const GOODS_UPLOAD_BUTTON_SX = {
  borderRadius: '10px',
  padding: '10px',
  height: 40,
  marginLeft: 2,
};
