import styled from '@emotion/styled/macro';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import TotalHeader from '../../../components/Ui/TotalHeader';

import AccountsServices, { IAccounts } from '../../../services/AccountsServices';

import { colors } from '../../../Styles/ui';
import AddAccountButton from '../components/Button/AddAccountButton';
import ManagementTable from '../components/ReactTable/ManagementTable';

export type ILoadAccountsList = () => void;

const AccountIndex = () => {
  const { t } = useTranslation();

  const [accountCount, setAccountCount] = useState<number>(0);
  const [accountList, setAccountList] = useState<IAccounts[]>([]);

  const { mutate: getAccountsList, isLoading } = useMutation(() => AccountsServices.getAccountsList(), {
    onSuccess: (data) => {
      setAccountCount(data.count);
      setAccountList(data.accounts);
    },
  });

  const loadAccountsList: ILoadAccountsList = () => {
    getAccountsList();
  };

  useEffect(() => {
    loadAccountsList();
  }, []);

  return (
    <Wrapper>
      <TotalHeader text={t('management.manageCount').replace('@', accountCount.toString())} />
      <TableBox>
        <ManagementTable accountList={accountList} loadAccountsList={loadAccountsList} />
      </TableBox>
      <AddAcountBox>
        <AddAccountButton loadAccountsList={loadAccountsList} />
      </AddAcountBox>
    </Wrapper>
  );
};

export default AccountIndex;

const Wrapper = styled.div`
  color: ${colors.text};
  background-color: ${colors.num_222};
  border-radius: 10px;
  padding: 40px;
`;

const TableBox = styled.div`
  margin: 20px 0;
`;

const AddAcountBox = styled.div`
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
