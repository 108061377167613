import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { colors, spacing } from '../../../Styles/ui';

export interface ICalendarInputBox {
  date: dayjs.Dayjs | null;
  name: string;
  placeholder: string;
  onChangeHandler: ({ date, keyboardInputValue, name }: ICalendarHandlerParam) => void;
}

export interface ICalendarHandlerParam {
  date: any;
  keyboardInputValue?: string | undefined;
  name: string;
}

const CalendarInputBox = ({ date, name, onChangeHandler, placeholder }: ICalendarInputBox) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Wrapper>
        <DesktopDatePicker
          label='Date desktop'
          inputFormat='YYYY-MM-DD HH:mm:ss'
          value={date}
          onChange={(date) => onChangeHandler({ date, name })}
          renderInput={(params) => {
            console.log(params);
            const { InputProps, inputProps, inputRef, error } = params;

            return (
              <TextField
                fullWidth
                variant='standard'
                InputProps={{
                  ...InputProps,
                  disableUnderline: true,

                  sx: {
                    padding: '0px 16px',
                    '.MuiInputBase-input': {
                      padding: '0px 0px',
                      height: '50px',
                    },
                  },
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={inputProps}
                inputRef={inputRef}
                sx={{
                  border: 'solid 0px',
                  padding: '0',
                }}
                hiddenLabel
              />
            );
          }}
        />
      </Wrapper>
    </LocalizationProvider>
  );
};

export default CalendarInputBox;

const Wrapper = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  color: ${colors.text} !important;
  background-color: ${colors.num_333} !important;
  border-radius: 10px;

  .MuiInputBase-input {
    font: normal normal normal 15px/25px Noto Sans CJK KR;
    height: 40px;
    ${spacing.paddingX(5)};
    color: ${colors.text} !important;
    background-color: ${colors.num_333} !important;
    border-radius: 10px;
  }
`;

const SecLabel = styled.label`
  color: #929292;
  padding-right: 20px;
  font: normal normal normal 15px/21px Noto Sans CJK KR;
`;
