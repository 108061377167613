import styled from '@emotion/styled';
import { colors } from '../../../Styles/ui';
import ItemBox from '../components/ItemBox';

const HomeIndex = () => {
  return (
    <HomeComponent>
      <ItemBox subject='DAU' />
      <ItemBox subject='Retention' />
      <ItemBox subject='Daily 경품 응모 횟수' />
      <ItemBox subject='NU' />
      <ItemBox subject='유저의 평균 보유 포인트' />
      <ItemBox subject='경품 당첨 횟수' />
      <ItemBox subject='사용자 지역 정보' />
    </HomeComponent>
  );
};

export default HomeIndex;

const HomeComponent = styled.div`
  width: 100%
  min-width: 720px;
  height: auto;
  padding: 40px 40px;
  color: ${colors.text};
  background-color: ${colors.num_333};
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
`;
