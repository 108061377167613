const colors = {

  // main
  main: '#00A176',
  main_hover: '#1AAB84',

  // nav
  nav: '#009900',
  indicator: '#5942ba',

  // live
  live_active: '#003426',

  // realtime
  rt_boundary: '#3c3c3c',

  // progress_bar
  progress: '#6F48F9',
  pg_down: '#F55400',

  // Quiz Label
  quiz_auto_wait: '#073653',
  quiz_playing: '#00573F',
  quiz_result_close: '#672121',
  quiz_progress: '#6A4E00',
  quiz_vote_close: '#532F07',

  // btn
  btn_confirm: '#00A176',
  btn_confirm_hover: '#1AAB84',
  btn_cancel: '#444444',
  btn_cancel_hover: '#444444',
  btn_continue: '#00513B',
  btn_corrected: '#555555',
  btn_corrected_hover: '#555555',
  btn_corrected_disabled: '#555555',
  btn_correct: '#C14747',
  btn_wating: '#068495',
  btn_playing: '#00573F',
  btn_end_vote: '#532F07',
  btn_end_result: '#672121',

  // bg
  bg_transparency: 'rgba(0,0,0,0)',

  // line
  outline: '#484848',

  // number
  num_111: '#111111',
  num_222: '#222222',
  num_333: '#333333',
  num_444: '#444444',
  num_555: '#555555',
  num_888: '#888888',
  num_999: '#999999',
  num_ccc: '#cccccc',

  // good_state
  goods_live: '#af51ff',
  goods_stop: '#7f7f7f',


  // font
  text: '#ffffff',
  text_none: '#919191',
  purple_gem: '#bf73ff',
  green_gem: '#6ec3a5',

  // graph
  graph: '#8069e3',
  보라색: {
    50: '#eae7f4',
    100: '#cbc6e6',
    200: '#a9a1d6',
    300: '#887cc7',
    400: '#6d5fbb',
    500: '#5442b0',
    600: '#4b3daa',
    700: '#4135a1',
    800: '#362e9b',
    900: '#24218c',
  },

  배경색: {
    50: '#e4deff',
    100: '#9a96a8',
    200: '#87859a',
    300: '#817e90',
    400: '#635f71',
    500: '#484655',
    600: '#403f50',
    700: '#383254',
    800: '#2f2e3b',
    900: '#242434',
  },
};

export default colors;
