import { atom, AtomEffect, selector } from 'recoil';

export type UserType = {
  // Authority: string;
  token: string;
  uid: string;
  accountIdx: number;
  lang: string;
  securityLevel: number;
};

interface IGameList {
  key: string;
}

const SessionEffect =
  (key: string): AtomEffect<UserType | undefined> =>
  ({ setSelf, onSet }) => {
    const sessionUser = sessionStorage.getItem(key);
    if (sessionUser && sessionUser !== 'undefined') {
      setSelf(JSON.parse(sessionUser));
    }

    onSet((newValue, oldValue, isReset) => {
      // eslint-disable-next-line no-unused-expressions
      isReset ? sessionStorage.removeItem(key) : sessionStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const userAtom = atom<UserType | undefined>({
  key: 'user',
  default: undefined,
  effects: [SessionEffect('user'), () => {}],
});

export const userTokenAtom = selector<string | undefined>({
  key: 'userToken',
  get: ({ get }) => {
    const user = get(userAtom);
    return user ? user.token : undefined;
  },
  set: ({ get, set }, newValue) => {
    const user = get(userAtom);
    if (user) {
      set(userAtom, { ...user, token: newValue as string });
    }
  },
});

export const userLanguageAtom = selector<string>({
  key: 'userLanguage',
  get: ({ get }) => {
    const user = get(userAtom);
    return user ? user.lang : 'ko';
  },
  set: ({ get, set }, newValue) => {
    const user = get(userAtom);
    if (user) {
      set(userAtom, { ...user, lang: newValue as string });
    }
  },
});

export const permissionsAtom = {
  account: atom<boolean>({
    key: 'account',
    default: false,
  }),
};
