import styled from '@emotion/styled/macro';
import { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { colors } from '../../../Styles/ui';
import LeagueListBox from '../components/LeagueListBox';

const RewardIndex = () => {
  return (
    <Wrapper>
      <LeagueListBox />
      <Outlet />
    </Wrapper>
  );
};

export default RewardIndex;

const Wrapper = styled.div`
  width: 100%
  min-width: 720px;
  height: auto;
  padding: 40px 40px;
  color: ${colors.text};
  background-color: ${colors.num_222};
  border-radius: 10px;
  
`;
