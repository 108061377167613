import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { toastError, toastSuccess } from '../components/Toastify/ToasitifyContainer';
import geti18n from '../lib/geti18n';
import { axiosDelete, axiosGet, axiosPatch, axiosPost, axiosPut, axiosPutFile, IStatus } from './axios/axios';
import { IRewardParam } from './IReward';

export interface IUploadGoodsImageParam {
  goodsImageFile: File;
}
export interface IGetGoodsListParam {
  scheduleIdx: number;
}

export interface IGetOrDeleteGoodsDetailParam {
  idx: number;
}

export interface ICreateGoodsParam {
  scheduleIdx: number;
  name: string;
  url: string;
  imageUrl: string;
  dailyLimit: number;
  goodsValue: number;
  goodsRemainValue: number;
  rate: number;
  goodsState: number;
  goodsCost: {
    costIdx: number;
    costValue: number;
  }[];
  rewardGoodsCode: {
    [key: string]: string;
  }[];
}

export interface IUpdateGoodsParam {
  idx: number;
  name: string;
  url: string;
  imageUrl: string;
  dailyLimit: number;
  goodsValue: number;
  goodsRemainValue: number;
  rate: number;
  show: number;
  goodsState: number;
  goodsCost: IGoodsCost[];
  rewardGoodsCode: {
    [key: string]: string;
  }[];
}

export interface IUpdateGoodsShowParam {
  idx: number;
  show: number;
}

export interface IUpdateGoodsOrderParam {
  scheduleIdx: number;
  goodsIdxList: number[];
}

interface IGetGoodsListSuccess extends IStatus {
  goods: IGoods[];
}

interface IGetGoodsDetailSuccess extends IStatus {
  goodsDetail: IGoodsDetail;
}

interface IUploadGoodsImageSuccess extends IStatus {
  imageUrl: {
    origin: string; // unusable
    route: string; // useable
  };
}

export interface IGoodsDetail {
  name: string;
  url: string;
  imageUrl: string;
  dailyLimit: number;
  goodsValue: number;
  goodsRemainValue: number;
  rate: number;
  show: number;
  goodsState: number;
  goodsDetailCost: IGoodsCost[];
  goodsDetailCode: {
    code: string;
  }[];
}

export interface IGoodsCost {
  idx?: number;
  costName?: string;
  costIdx: number;
  costValue: number;
  costKey?: string;
}

export interface IGoods {
  show: number;
  idx: number;
  name: string;
  url: string;
  imageUrl: string;
  goodsValue: number;
  goodsRemainValue: number;
  cost: IGoodsCost[];
}

class GoodsServices {
  uploadGoodsImage(data: IUploadGoodsImageParam): Promise<IUploadGoodsImageSuccess> {
    return new Promise((resolve, reject) => {
      axiosPutFile({
        url: '/goods/image/upload',
        data,
      })
        .then((res: AxiosResponse) => {
          console.log('res', res);
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          console.log('err', err);
          reject(err);
        });
    });
  }

  createGoods(data: ICreateGoodsParam): Promise<IStatus> {
    return new Promise((resolve, reject) => {
      axiosPut({
        url: '/goods/create',
        data,
      })
        .then((res: AxiosResponse) => {
          console.log('res', res);
          resolve(res.data);
          toastSuccess(geti18n('reward.toastify.success.updateGoodsSuccess'));
        })
        .catch((err: AxiosError) => {
          console.log('err', err);
          reject(err);
          toastError(geti18n('reward.toastify.fail.updateGoodsFail'));
        });
    });
  }

  updateGoods(data: IUpdateGoodsParam): Promise<IStatus> {
    return new Promise((resolve, reject) => {
      axiosPatch({
        url: '/goods/update',
        data,
      })
        .then((res: AxiosResponse) => {
          console.log('res', res);
          resolve(res.data);
          toastSuccess(geti18n('reward.toastify.success.updateGoodsSuccess'));
        })
        .catch((err: AxiosError) => {
          console.log('err', err);
          reject(err);
          toastError(geti18n('reward.toastify.fail.updateGoodsFail'));
        });
    });
  }

  getGoodsList({ scheduleIdx }: IGetGoodsListParam): Promise<IGetGoodsListSuccess> {
    return new Promise((resolve, reject) => {
      axiosGet({
        url: `/goods/read/${scheduleIdx}`,
      })
        .then((res: AxiosResponse) => {
          console.log('res', res);
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          console.log('err', err);
          reject(err);
        });
    });
  }

  getGoodsDetail(data: IGetOrDeleteGoodsDetailParam): Promise<IGetGoodsDetailSuccess> {
    return new Promise((resolve, reject) => {
      axiosGet({
        url: `/goods/detail/${data.idx}`,
      })
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  }

  deleteGoods(data: { idx: number }): Promise<IStatus> {
    return new Promise((resolve, reject) => {
      axiosDelete({
        url: `/goods/delete`,
        data,
      })
        .then((res: AxiosResponse) => {
          console.log('res', res);
          resolve(res.data);
          toastSuccess(geti18n('reward.toastify.success.deleteGoods'));
        })
        .catch((err: AxiosError) => {
          console.log('err', err);
          reject(err);
          toastError(geti18n('reward.toastify.fail.deleteGoods'));
        });
    });
  }

  updateGoodsShow(data: IUpdateGoodsShowParam): Promise<IStatus> {
    return new Promise((resolve, reject) => {
      axiosPatch({
        url: `/goods/update/show`,
        data,
      })
        .then((res: AxiosResponse) => {
          let text = 'success';
          if (data.show === 1) {
            text = 'updateGoodsShowStop';
          }
          if (data.show === 0) {
            text = 'updateGoodsShowRevisible';
          }
          toastSuccess(geti18n(`reward.toastify.success.${text}`));

          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          let text = 'success';
          if (data.show === 0) {
            text = 'updateGoodsShowStop';
          }
          if (data.show === 1) {
            text = 'updateGoodsShowRevisible';
          }
          toastError(geti18n(`reward.toastify.fail.${text}`));
          reject(err);
        });
    });
  }

  updateGoodsOrder(data: IUpdateGoodsOrderParam): Promise<IStatus> {
    return new Promise((resolve, reject) => {
      axiosPatch({
        url: `/goods/order`,
        data,
      })
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  }
}

export default new GoodsServices();
