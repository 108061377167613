import { Avatar, Box, Button, Chip, Stack, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import styled from '@emotion/styled';
import { DndContext } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import GoodsServices, { IGetGoodsListParam, IGoods, IGoodsCost } from '../../../services/GoodsServices';
import GoodsEditButton from './Goods/GoodsEditButton';
import { ISchedule } from '../../../services/ScheduleServices';
import GoodsShowButton from './Goods/GoodsShowButton';
import GoodsDeleteButton from './Goods/GoodsDeleteButton';
import GoodsCreateBox from './Goods/GoodsCreateBox';
import { ILoadGoodsList } from './Goods/GoodsItemListBox';
import { DRAG_STATE_MAP } from '../lib/reward_map';

const WebGoods = ({
  scheduleIdx: idx,
  goods,
  loadGoodsList,
  dragState,
}: {
  scheduleIdx: number;
  goods: IGoods;
  loadGoodsList: ILoadGoodsList;
  dragState: string;
}) => {
  const { t } = useTranslation();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: goods.idx });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box sx={{ width: 'auto', margin: '10px' }}>
      <li ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <Box
          key={goods.idx}
          sx={{
            position: 'relative',
            // width: 'calc(25% - 8px)',
            mb: 2,
            ':not(:last-of-type)': {
              mr: 2,
            },
            bgcolor: 'background.modal',
            backgroundImage: `url(/Images/background/reward-bg-gradient.png)`,
            backgroundSize: 'cover',
            borderRadius: 2,
          }}
        >
          <Chip
            label={t('구매가능', { ns: 'reward' })}
            sx={{
              // position: 'absolute',
              marginTop: '12px',
              marginLeft: '16px',
              backgroundImage: 'linear-gradient(109deg, #613389 3%, #0b8966 100%);',
              color: 'white',
              opacity: '1',
              borderRadius: 1,
              fontWeight: 'bold',
              fontSize: 13,
              '&.MuiChip-root': {
                height: '20px',
              },
              '& .MuiChip-label': {
                padding: '2px',
              },
            }}
          />
          <Box sx={{ padding: '24px 40px', mt: 5 }}>
            <Avatar
              variant='square'
              src={`https://image.decakill.com${goods?.imageUrl}`}
              alt='productImg'
              sx={{ width: 199, height: 199 }}
            />
          </Box>
          <Box
            sx={{
              p: 6,
              borderTop: '1px solid',
              borderColor: 'customMenuListColor.light',
              textAlign: 'left',
            }}
          >
            <Typography
              variant='h6'
              sx={{
                opacity: '1',
              }}
            >
              {goods.name}
            </Typography>
            <Typography variant='body2'>
              {goods.goodsRemainValue}
              {t('갯수', { ns: 'common' })} {t('남음', { ns: 'reward' })} /&nbsp;
              {t('총', { ns: 'common' })}&nbsp;
              {goods.goodsValue}
              {t('갯수', { ns: 'common' })}
            </Typography>
          </Box>
          <Stack
            direction='row'
            spacing={2}
            sx={{
              p: 6,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {goods.cost?.map((el: IGoodsCost) => {
              const { costIdx, costValue } = el;

              return (
                <>
                  <Button
                    variant='contained'
                    sx={{ bgcolor: 'background.default', width: '100%', borderRadius: 2 }}
                  >
                    <Avatar
                      sx={{
                        width: 24,
                        height: 24,
                      }}
                      alt='gem'
                      src={`/Images/Icon/ico-${costIdx === 2 ? 'streamer' : 'league'}-pt.svg`}
                    />
                    <Typography
                      variant='body2'
                      sx={{
                        color: costIdx === 2 ? 'secondary.main' : 'primary.main',
                      }}
                    >
                      {costValue.toLocaleString()}
                    </Typography>
                  </Button>
                </>
              );
            })}
          </Stack>
        </Box>
      </li>
      <ButtonBox dragState={dragState}>
        {goods.show === 1 ? <GoodsEditButton goodsIdx={goods.idx} loadGoodsList={loadGoodsList} /> : <></>}
        <GoodsShowButton
          goodsIdx={goods.idx}
          goodsName={goods.name}
          loadGoodsList={loadGoodsList}
          show={goods.show}
        />
        {goods.show === 1 ? (
          <GoodsDeleteButton goodsIdx={goods.idx} goodsName={goods.name} loadGoodsList={loadGoodsList} />
        ) : (
          <></>
        )}
      </ButtonBox>
    </Box>
  );
};

export default WebGoods;

const ButtonBox = styled.div<{ dragState: string }>`
  display: flex;
  visibility: ${({ dragState }) => (dragState === DRAG_STATE_MAP.end ? 'visible' : 'hidden')};
  justify-content: space-around;
`;
