import React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Column, useTable } from 'react-table';
import makeData, { IManagementTable } from './makeData';
import EditAccountButton from '../Button/EditAccountButton';
import DeleteAccountButton from '../Button/DeleteAccountButton';
import UnBlockAcoountButton from '../Button/UnBlockAcoountButton';
import { colors } from '../../../../Styles/ui';
import { IAccounts } from '../../../../services/AccountsServices';
import { ILoadAccountsList } from '../../screens/AccountIndex';

interface ITable {
  columns: Column<IManagementTable>[];
  data: IManagementTable[];
  loadAccountsList: ILoadAccountsList;
}

function Table({ columns, data, loadAccountsList }: ITable) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const needColorGreen = (id: string, value: string | number) => {
    if (['usableRegistetProduct', 'usableGradeManage'].includes(id) && value === 'O') return true;
    return false;
  };

  const neeeIcon = (id: string) => {
    if (['edit', 'delete', 'isBlock'].includes(id)) return true;
    return false;
  };

  interface IIconData {
    uid: string;
    idx: number;
    value: boolean;
    id: string;
    password?: string;
    isBlock: boolean;
    usableGradeManage: string;
    grade: string;
  }

  const getFunctionIcon = (data: IIconData) => {
    if (typeof data.value === 'boolean' && data.value === false) return <></>;

    const { uid, idx, id, password, isBlock, grade, usableGradeManage } = data;

    if (data.id === 'edit') {
      return (
        <EditAccountButton
          idx={idx}
          selectedUid={uid}
          password={password}
          grade={grade}
          block={isBlock}
          loadAccountsList={loadAccountsList}
        />
      );
    }
    if (id === 'delete') {
      return <DeleteAccountButton idx={idx} selectedUid={uid} loadAccountsList={loadAccountsList} />;
    }
    if (id === 'isBlock') {
      return <UnBlockAcoountButton idx={idx} selectedUid={uid} />;
    }
    return <></>;
  };

  // Render the UI for your table
  return (
    <MuiTable {...getTableProps()}>
      <TableHead sx={{ backgroundColor: colors.num_111, borderTop: `solid 2px ${colors.main}` }}>
        {headerGroups.map((headerGroup: any) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <TableCell
                sx={{ color: colors.text, textAlign: 'center', borderBottomColor: colors.num_444 }}
                {...column.getHeaderProps()}
              >
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row: any, i: number) => {
          prepareRow(row);
          const idx = row.values?.no;
          const uid = row.values?.id;
          const isBlock = row.values?.isBlock;
          const usableGradeManage = row.values?.usableGradeManage;
          const grade = row.values?.grade;
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell: any) => {
                const { id } = cell.column;
                const { value } = cell;
                return (
                  <TableCell
                    sx={{
                      color: needColorGreen(cell.column.id, cell.value) ? colors.main : colors.num_ccc,
                      textAlign: 'center',
                      borderBottomColor: colors.num_444,
                    }}
                    {...cell.getCellProps()}
                  >
                    {neeeIcon(id)
                      ? getFunctionIcon({
                          id,
                          value,
                          idx,
                          uid,
                          isBlock,
                          usableGradeManage,
                          grade,
                        })
                      : cell.render('Cell')}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </MuiTable>
  );
}

interface IManagementTableParam {
  accountList: IAccounts[];
  loadAccountsList: ILoadAccountsList;
}

const ManagementTable = ({ accountList, loadAccountsList }: IManagementTableParam) => {
  const { t } = useTranslation();

  const columnDataset: Column<IManagementTable>[] = [
    {
      Header: t('management.accountTable.header.no'),
      accessor: 'no',
    },
    {
      Header: t('management.accountTable.header.id'),
      accessor: 'id',
    },
    {
      Header: t('management.accountTable.header.grade'),
      accessor: 'grade',
    },
    {
      Header: t('management.accountTable.header.usableRegistetProduct'),
      accessor: 'usableRegistetProduct',
    },
    {
      Header: t('management.accountTable.header.usableGradeManage'),
      accessor: 'usableGradeManage',
    },
    {
      Header: t('management.accountTable.header.isBlock'),
      accessor: 'isBlock',
    },
    {
      Header: t('management.accountTable.header.edit'),
      accessor: 'edit',
    },
    {
      Header: t('management.accountTable.header.delete'),
      accessor: 'delete',
    },
  ];

  const columns = React.useMemo(() => columnDataset, [accountList]);
  const data = React.useMemo<IManagementTable[]>(() => makeData(accountList), [accountList]);

  return (
    <Wrapper>
      <CssBaseline />
      <Table columns={columns} data={data} loadAccountsList={loadAccountsList} />
    </Wrapper>
  );
};

export default ManagementTable;

const Wrapper = styled.div`
  .MuiIconButton-root {
    padding: 0;
  }
`;
