import { Box, Button, FormControl, Input, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import CalendarInputBox, { ICalendarHandlerParam } from '../../../../components/Ui/Input/CalendarInputBox';
import {
  MODAL_CANCEL_BUTTON_SX,
  MODAL_FORM_CONTROL_SX,
  MODAL_MENU_PROPS,
  MODAL_SELECT_SX,
  MODAL_STYLED,
} from '../../../../components/Ui/Modal/lib/modal_css';
import {
  CustomInput,
  CustomLabel,
  InputBox,
  ModalBodyInputPaddingZero,
  modalButtonStyle,
  ModalHeader,
} from '../../../../components/Ui/Modal/lib/modal_styled';
import { colors } from '../../../../Styles/ui';
import { ButtonBox } from '../../lib/reward_styled';
import { IReloadScheduleList } from '../RewardTable/Schedule/ScheduleTableComponent';
import ScheduleServices, { ICreateScheduleParam } from '../../../../services/ScheduleServices';

export interface ICreateScheduleModalParam {
  handleClose: () => void;
  gameIdx: number;
  leagueIdx: number;
  reloadScheduleList: IReloadScheduleList;
}

const showList = [0, 1];
const convShow = (show: number) => {
  switch (show) {
    case 1:
      return 'false';
    case 0:
      return 'true';
    default:
      return 'false';
  }
};

const CreateScheduleModalBox = ({
  handleClose,
  gameIdx,
  leagueIdx,
  reloadScheduleList,
}: ICreateScheduleModalParam) => {
  const { t } = useTranslation();
  const param = useParams();
  const [scheduleName, setScheduleName] = useState<string>('');
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(dayjs().hour(0).minute(0).second(0));
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(dayjs().hour(23).minute(59).second(59));
  const [show, setShow] = useState<number>(0);

  const onConfirm = () => {
    if (startDate && endDate) {
      const data: ICreateScheduleParam = {
        gameIdx,
        leagueIdx,
        name: scheduleName,
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss.ms'),
        endDate: endDate.format('YYYY-MM-DDTHH:mm:ss.ms'),
        show,
        state: 1, // create 일 땐 1로 고정.
      };
      createSchedule(data);
    }
  };

  const { mutate: createSchedule, isLoading } = useMutation(
    (data: ICreateScheduleParam) => ScheduleServices.createSchedule(data),
    {
      onSuccess: (data) => {
        reloadScheduleList();
        handleClose();
      },
    },
  );

  const handleChangeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name === 'scheduleName') {
      setScheduleName(value);
    }
  }, []);

  const handleChangeSelect = useCallback((e: SelectChangeEvent<number>) => {
    const { value, name } = e.target;
    if (name === 'show') {
      setShow(+value);
    }
  }, []);

  const handleChangeDate = useCallback((data: ICalendarHandlerParam) => {
    const { name, date } = data;
    if (name === 'startDate') {
      setStartDate(date);
    }
    if (name === 'endDate') {
      setEndDate(date);
    }
  }, []);

  return (
    <Box sx={MODAL_STYLED}>
      <ModalHeader>{t('reward.modal.title.createSchedule')}</ModalHeader>
      <ModalBodyInputPaddingZero>
        <InputBox>
          <CustomLabel>{t('reward.modal.label.scheduleName')}</CustomLabel>
          <CustomInput
            defaultValue={scheduleName}
            type='text'
            name='scheduleName'
            autoComplete='off'
            onChange={handleChangeInput}
            placeholder={t('reward.modal.label.scheduleName')}
          />
        </InputBox>
        <InputBox>
          <CustomLabel>{t('reward.table.schedule.startDate')}</CustomLabel>
          <CalendarInputBox
            name='startDate'
            date={startDate}
            onChangeHandler={handleChangeDate}
            placeholder={t('reward.table.schedule.startDate')}
          />
        </InputBox>
        <InputBox>
          <CustomLabel>{t('reward.table.schedule.endDate')}</CustomLabel>
          <CalendarInputBox
            name='endDate'
            date={endDate}
            onChangeHandler={handleChangeDate}
            placeholder={t('reward.table.schedule.endDate')}
          />
        </InputBox>
        <InputBox>
          <CustomLabel>Show</CustomLabel>
          <FormControl sx={MODAL_FORM_CONTROL_SX}>
            <Select
              sx={MODAL_SELECT_SX}
              value={show}
              label='show'
              name='show'
              onChange={handleChangeSelect}
              input={<Input disableUnderline />}
              renderValue={(selected) => <SelectedLabel>{convShow(selected)}</SelectedLabel>}
              MenuProps={MODAL_MENU_PROPS}
            >
              {showList.map((value) => (
                <MenuItem key={value} value={value}>
                  {convShow(value)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </InputBox>
      </ModalBodyInputPaddingZero>
      <ButtonBox>
        <Button onClick={handleClose} variant='contained' sx={MODAL_CANCEL_BUTTON_SX}>
          {t('common.button.cancel')}
        </Button>
        <Button sx={modalButtonStyle} variant='contained' onClick={onConfirm}>
          {t('common.button.confirm')}
        </Button>
      </ButtonBox>
    </Box>
  );
};

export default CreateScheduleModalBox;

const SelectedLabel = styled.label`
  color: ${colors.text};
  padding: 0 16px;
`;
