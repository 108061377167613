import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import gameAtom, { Ileague } from '../../../recoil/game/gameAtom';
import { colors } from '../../../Styles/ui';

const LeagueListBox = () => {
  const gameList = useRecoilValue(gameAtom.gameList);
  const param = useParams();
  const [leagueList, setLeagueList] = useState<Ileague[]>([]);
  const selectedGame = param.game ?? '';
  const navigate = useNavigate();

  const clickHandler = (key: string) => {
    return navigate(`${key}`);
  };

  useEffect(() => {
    if (selectedGame && gameList.length > 0) {
      const game = gameList.filter((data) => data.key === selectedGame);
      if (game.length > 0) {
        setLeagueList(game[0].league);
      }
    }
  }, [gameList, param.game]);

  useEffect(() => {
    const { game, league } = param;
    if (leagueList.length > 0 && game && !league) {
      clickHandler(leagueList[0].key);
    }
  }, [leagueList, param.league]);

  // 나중에 MSI 이름 바꿔달라고 요청 해야함.

  return (
    <Wrapper>
      {leagueList.map((league) => {
        const name = league.key.toLowerCase();
        return (
          <LeagueBox>
            <LeagueImgBox active={param.league === league.key} onClick={() => clickHandler(league.key)}>
              <LeagueImg src={`/Images/league/ico-league-${name}.png`} />
            </LeagueImgBox>
            {league.key}
          </LeagueBox>
        );
      })}
    </Wrapper>
  );
};

export default LeagueListBox;

const Wrapper = styled.div`
  width: 100%;
  height: 177px;
  background-color: ${colors.num_333};
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px 10px 0px 0px;
`;

const LeagueBox = styled.div`
  height: 112px;
  text-align: center;
`;

const LeagueImgBox = styled.button<{ active: boolean }>`
  width: 82px;
  height: 82px;
  background-color: ${({ active }) => (active ? colors.btn_confirm : colors.num_222)};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;

  :hover {
    background-color: ${colors.btn_confirm};
  }
`;

const LeagueImg = styled.img`
  width: 46px;
  height: 46px;
`;
