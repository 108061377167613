import styled from '@emotion/styled/macro';
import { colors } from '../../Styles/ui';

export const ContentWrapper = styled.div`
  width: 100%;
  // max-width: 1920px;
  min-width: 720px;
  height: 95%;
  padding: 0px 40px;
  color: ${colors.text};
`;

export const FlexBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
