import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { t } from 'i18next';
import qs from 'qs';
import { toastError, toastSuccess } from '../../components/Toastify/ToasitifyContainer';
import { LiveAPI, mainUrl, TestAPI } from '../../Pages/config';
import { IBlockCountData } from '../AuthServices';
// import { API } from '../../Pages/config';

const baseUrl = window.location.hostname === mainUrl ? LiveAPI : TestAPI;
const serverError = 'axios.serverError';
const apiError = 'axios.apiError';
const updateSuccess = 'axios.updateSuccess';
const putSuccess = 'axios.putSuccess';
const deleteSuccess = 'axios.deleteSuccess';

function geti18n(text: string): string {
  return t(text);
}

export interface IAxiosResponse<T> extends IStatus {
  message?: string;
  users?: T;
  errorMsg?: string;
}

export interface IResponseSuccess<T> {
  response: T;
  status: string;
  message: string;
}

export interface IStatus {
  status: IStatusItems;
}

export interface IStatusItems {
  auth: string;
  description: string;
  message: string;
  status_code: number;
  error_code: number;
  blockCountData?: IBlockCountData;
}

export class ApiError implements IAxiosResponse<undefined> {
  status: IStatusItems;
  message: string;

  constructor(status: IStatusItems, message = 'SomeThing Wrong') {
    this.status = status;
    this.message = message;
  }
}

const axiosCommonProperty = {
  baseURL: `${baseUrl}`,
  timeout: 5000,
};
export const axiosGetNoLogin = axios.create({
  ...axiosCommonProperty,
  method: 'GET',
});

export const axiosPost = axios.create({
  ...axiosCommonProperty,
  method: 'POST',
});

export const axiosPatch = axios.create({
  ...axiosCommonProperty,
  method: 'PATCH',
});

export const axiosPut = axios.create({
  ...axiosCommonProperty,
  method: 'PUT',
});

export const axiosDelete = axios.create({
  ...axiosCommonProperty,
  method: 'DELETE',
});

export const axiosGet = axios.create({
  ...axiosCommonProperty,
  method: 'GET',
});

export const axiosPutFile = axios.create({
  ...axiosCommonProperty,
  method: 'PUT',
});

const addUserData = (config: AxiosRequestConfig) => {
  const user = sessionStorage.getItem('user');

  if (user) {
    const { userId, token } = JSON.parse(user);
    const _config = {
      ...config,
      headers: { Authorization: `Bearer ${token}` },
      data: { ...config.data, id: userId, token },
    };
    return _config;
  }
  throw new Error('user data is none.');
};

const addHeader = (config: AxiosRequestConfig) => {
  const user = sessionStorage.getItem('user');
  if (user) {
    const { token } = JSON.parse(user);
    const _config = {
      ...config,
      headers: { Authorization: `Bearer ${token}` },
    };
    return _config;
  }
  throw new Error('user data is none.');
};

const addFileHeader = (config: AxiosRequestConfig) => {
  const user = sessionStorage.getItem('user');
  if (user) {
    const { token } = JSON.parse(user);
    const _config = {
      ...config,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };
    return _config;
  }
  throw new Error('user data is none.');
};

const handleUseUser = (config: AxiosRequestConfig) => {
  const _config = addUserData(config);
  return _config;
};

const handleUseHeader = (config: AxiosRequestConfig) => {
  const _config = addHeader(config);
  return _config;
};

const handleFileHeader = (config: AxiosRequestConfig) => {
  const _config = addFileHeader(config);
  return _config;
};

const handleError = (error: any) => {
  return Promise.reject(error);
};

// 요청 인터셉터
axiosPost.interceptors.request.use(handleUseHeader);
axiosGet.interceptors.request.use(handleUseHeader);
axiosPut.interceptors.request.use(handleUseHeader);
axiosDelete.interceptors.request.use(handleUseHeader);
axiosPatch.interceptors.request.use(handleUseHeader);
axiosGetNoLogin.interceptors.request.use(handleUseHeader);
axiosPutFile.interceptors.request.use(handleFileHeader);

// 공통 Intercept Response
const commonInterceptorResponse = (res: AxiosResponse<IAxiosResponse<string[]>>) => {
  // http 기본응답
  if (res.status > 299 || res.status < 200) {
    console.error('서버에 호출이 들어가지 않았습니다. url을 확인하세요');
    toastError(geti18n(serverError));
  }

  // 서버에서 호출하는 응답 schema
  if (Number(res.data.status.status_code) > 299 || Number(res.data.status.status_code) < 200) {
    const { status, message } = res.data;
    const error = new ApiError(status, message);
    toastError(geti18n(apiError));
    return Promise.reject(error);
  }
  return res;
};

// Toastify 사용 Intercept Response
const useToastResponse = (res: AxiosResponse<IAxiosResponse<string[]>>) => {
  const result = commonInterceptorResponse(res);
  const { method } = res.config;
  console.log('method', method);
  switch (method) {
    case 'put':
      toastSuccess(geti18n(putSuccess));
      break;
    case 'delete':
      toastSuccess(geti18n(deleteSuccess));
      break;
    case 'patch':
      toastSuccess(geti18n(updateSuccess));
      break;
    default:
  }
  return result;
};

// using commonInterceptorResponse
axiosPost.interceptors.response.use(commonInterceptorResponse, handleError);
axiosGet.interceptors.response.use(commonInterceptorResponse, handleError);
axiosGetNoLogin.interceptors.response.use(commonInterceptorResponse, handleError);
axiosPatch.interceptors.response.use(commonInterceptorResponse, handleError);
axiosPut.interceptors.response.use(commonInterceptorResponse, handleError);
axiosPutFile.interceptors.response.use(commonInterceptorResponse, handleError);
axiosDelete.interceptors.response.use(commonInterceptorResponse, handleError);

// using useToastResponse
// axiosPatch.interceptors.response.use(useToastResponse, handleError);
// axiosPut.interceptors.response.use(useToastResponse, handleError);
// axiosPutFile.interceptors.response.use(useToastResponse, handleError);
// axiosDelete.interceptors.response.use(useToastResponse, handleError);
