import axios, { AxiosError, AxiosResponse } from 'axios';
import QueryString from 'qs';
import { LiveAPI, mainUrl, TestAPI } from '../Pages/config';
import { ApiError, axiosGet, axiosPost, IAxiosResponse, IResponseSuccess, IStatus } from './axios/axios';

export interface IAccountsSuccess extends IStatus {
  loginUser: {
    token: string;
    idx: number;
    uid: string;
    securityLevel: number;
  };
  blockCountData?: IBlockCountData;
}

export interface IBlockCountData {
  uid: string;
  blockCount: number;
}

export interface ILoginParam {
  uid: string;
  password: string;
}

const baseUrl = window.location.hostname === mainUrl ? LiveAPI : TestAPI;

const authAxios = axios.create({
  baseURL: `${baseUrl}`,
  method: 'POST',
  timeout: 5000,
});

const handleSuccess = (res: AxiosResponse<IAxiosResponse<undefined>>) => {
  // 호출 기본응답
  if (res.status > 299 || res.status < 200) {
    console.error('서버에 호출이 들어가지 않았습니다. url을 확인하세요');
  }

  // 서버에서 호출하는 응답
  if (Number(res.data.status.status_code) > 299 || Number(res.data.status.status_code) < 200) {
    const { message } = res.data.status;
    const error = new ApiError(res.data.status, message);
    return Promise.reject(error);
  }
  return res;
};

const handleError = (error: AxiosError) => {
  return Promise.reject(error);
};

// 응답 인터셉터
authAxios.interceptors.response.use(handleSuccess, handleError);

class AuthServices {
  // getClientUserByToken = ({ id }: { id: string }) => {
  //   return new Promise((resolve, reject) => {
  //     axiosPost({
  //       url: '/user/login',
  //       data: { id },
  //     })
  //       .then((res: AxiosResponse) => {
  //         console.log('res', res);
  //         resolve(res);
  //       })
  //       .catch((err: AxiosError) => {
  //         console.log('err', err);
  //         reject(err);
  //       });
  //   });
  // };

  login(data: ILoginParam): Promise<IAccountsSuccess> {
    return new Promise((resolve, reject) => {
      authAxios({
        url: '/login',
        data,
      })
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosResponse) => {
          reject(err);
        });
    });
  }
}

export default new AuthServices();

// https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=73lyznsjqny8ka0gu2gjizkn71vesc&redirect_uri=https%3A%2F%2Fapi.t1live.gg%2Fuser%2Flogin_callback%2Ftwitch&scope=user%3Aread%3Aemail&state=58bcd6c263eea5ab89b23dca12de3f35667ff914
// https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=jw4iqgjsxtgrap41i8ra7a55540r92&redirect_uri=http://localhost:3000&scope=channel%3Amanage%3Apolls+channel%3Aread%3Apolls

// ‘https://id.twitch.tv/oauth2/token’ \
// -H ‘Content - Type: application / x - www - form - urlencoded’ \
// -d ‘client_id = jw4iqgjsxtgrap41i8ra7a55540r92 & client_secret=75py2fphuc1q8kivqqoqzu6zrh7s6v & code=hhwvnyb8ml2z9qa0uwjvrtxpxvhgge & grant_type=authorization_code & redirect_uri=http://localhost:3000’
// https://api.twitch.tv/helix/users
