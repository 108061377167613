import { AxiosError, AxiosResponse } from 'axios';
import { toastError, toastSuccess } from '../components/Toastify/ToasitifyContainer';
import geti18n from '../lib/geti18n';
import { axiosDelete, axiosGet, axiosPatch, axiosPost, axiosPut, IStatus } from './axios/axios';
import { IRewardParam } from './IReward';

interface IScheduleParam {
  name: string;
  startDate: string;
  endDate: string;
  show: number;
  state: number;
}

export interface ICreateScheduleParam extends IScheduleParam {
  gameIdx: number;
  leagueIdx: number;
}

export interface IUpdateScheduleParam extends IScheduleParam {
  idx: number;
}

export interface IDeleteScheduleParam {
  idx: number;
}

interface IGetScheduleListSuccess extends IStatus {
  scheduleRead: ISchedule[];
}

export interface ISchedule {
  idx: number;
  leagueIdx: number;
  name: string;
  startDate: string;
  endDate: string;
  show: number;
  state: number;
  del: number;
  regdate: string;
}

class ScheduleServices {
  getScheduleList(data: IRewardParam): Promise<IGetScheduleListSuccess> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: `/schedule/read`,
        data,
      })
        .then((res: AxiosResponse) => {
          console.log('res', res);
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          console.log('err', err);
          reject(err);
        });
    });
  }

  createSchedule(data: ICreateScheduleParam): Promise<IStatus> {
    return new Promise((resolve, reject) => {
      axiosPut({
        url: '/schedule/create',
        data,
      })
        .then((res: AxiosResponse) => {
          console.log('res', res);
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          console.log('err', err);
          reject(err);
        });
    });
  }

  updateSchedule(data: IUpdateScheduleParam): Promise<IStatus> {
    return new Promise((resolve, reject) => {
      axiosPatch({
        url: '/schedule/update',
        data,
      })
        .then((res: AxiosResponse) => {
          console.log('res', res);
          toastSuccess(geti18n(`reward.toastify.success.updateSchedule`));
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          toastError(geti18n(`reward.toastify.fail.updateSchedule`));
          console.log('err', err);
          reject(err);
        });
    });
  }

  deleteScheduleTheme(data: IDeleteScheduleParam): Promise<IStatus> {
    return new Promise((resolve, reject) => {
      axiosDelete({
        url: '/schedule/delete',
        data,
      })
        .then((res: AxiosResponse) => {
          console.log('res', res);
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          console.log('err', err);
          reject(err);
        });
    });
  }
}

export default new ScheduleServices();
