import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';
import Header from './components/Header/HeaderContent';
import SidebarContent from './components/Sidebar/SidebarContent';
import { colors, scrollbarStyle } from './Styles/ui';

const Layout = () => {
  return (
    <CONTAINER>
      <SidebarContent />
      <WRAPPER>
        <Header />
        <CONTENTS>
          <Outlet />
        </CONTENTS>
      </WRAPPER>
    </CONTAINER>
  );
};

const CONTAINER = styled.main`
  display: flex;
  overflow: hidden;
  background-color: ${colors.num_111};
`;

const WRAPPER = styled.article`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  overflow: hidden;
`;

const CONTENTS = styled.section`
  width: 100%;
  height: 100vh;
  ${scrollbarStyle.scroll_4};
`;

export default Layout;
