import { AxiosError, AxiosResponse } from 'axios';
import { axiosGet, axiosGetNoLogin, axiosPatch, axiosPost, IStatus } from './axios/axios';

interface IGetGameListSuccess extends IStatus {
  game: IGameSet[];
}

export interface IGameSet {
  idx: number;
  key: string;
  league: {
    idx: number;
    key: string;
  }[];
}

class GameServices {
  // 게임 & 리그 리스트
  getGameList(): Promise<IGetGameListSuccess> {
    return new Promise((resolve, reject) => {
      axiosGet({
        url: '/game/list',
      })
        .then((res: AxiosResponse) => {
          console.log('res', res);
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          console.log('err', err);
          reject(err);
        });
    });
  }
}

export default new GameServices();
