import styled from '@emotion/styled/macro';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { color, styled as MuiStyled } from '@mui/system';
import { Input, Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../recoil/Auth/userAtom';
import AuthServices, { ILoginParam } from '../../services/AuthServices';
import { colors, spacing } from '../../Styles/ui';

const LoginContent = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [user, setUser] = useRecoilState(userAtom);

  // useEffect(() => {
  //   client.connect({}, ()=>{
  //     console.log('Connected')
  //   })
  // }, [client]);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  const { mutate: login, isLoading } = useMutation((data: ILoginParam) => AuthServices.login(data), {
    onSuccess: (data) => {
      const { uid, token, idx, securityLevel } = data.loginUser;
      setUser({ uid, token, lang: 'ko', accountIdx: idx, securityLevel });
    },
  });

  const onSubmit = handleSubmit(({ uid, password }) => {
    login({ uid, password });
  });

  useEffect(() => {
    console.log('user', user);
  }, [user]);

  return (
    <>
      <LoginWrapper>
        <CONTENT>
          <FORM onSubmit={onSubmit}>
            <SUBJECT>DECAKILL MASTER</SUBJECT>
            <Input
              disableUnderline
              fullWidth
              type='text'
              // className='id'
              id='uid'
              placeholder='ID'
              autoComplete='off'
              {...register('uid')}
            />
            <Input
              disableUnderline
              fullWidth
              type='password'
              // className='password'
              id='password'
              // name='password'
              autoComplete='off'
              placeholder='PASSWORD'
              {...register('password')}
            />
            <LOADINGBUTTON type='submit' loading={isLoading} variant='contained'>
              LOGIN
            </LOADINGBUTTON>
            {/* <SingUpBox>
            {t('sign.needToSign')}
            <span tabIndex='-1' role='button' onClick={goSignUp} onKeyUp={goSignUp}>
              Sign Up
            </span>
          </SingUpBox> */}
            <COPYRIGHT>Copyright Team Snowball All Rights Reserved.</COPYRIGHT>
            {/* <ERRORTEXT>{(errors.id && errors.id.message) || (errors.total && errors.total.message)}</ERRORTEXT> */}
          </FORM>
        </CONTENT>
      </LoginWrapper>
    </>
  );
};

export default LoginContent;

const LoginWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background: #111111 0% 0% no-repeat padding-box;
  justify-content: center;
  align-items: center;
`;

const CONTENT = styled.div`
  width: 1216px;
  height: 290px;
  background-image: url('/Images/bg-login.png');
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FORM = styled.form`
  width: 300px;
  height: 236px;

  &::-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
    box-shadow: 0 0 0 1000px white inset;
  }

  .MuiInput-input {
    font: normal normal normal 15px/25px Noto Sans CJK KR;
    // ${spacing.paddingY(3.25)};
    height: 40px;
    ${spacing.paddingX(5)};
    ${spacing.marginB(3)};
    color: ${colors.text} !important;
    background-color: ${colors.num_333} !important;
    border-radius: 10px;
  }
`;

const SUBJECT = styled.div`
  margin-top: 42px;
  margin-bottom: 21px;
  width: 100%;
  text-align: center;
  color: ${colors.text};
  font: normal normal bold 24px/25px Poppins;
`;

// const ERRORTEXT = styled.p`
//   ${spacing.paddingY(2)}
//   color: ${colors.badge_red};
//   font-size: 11px;
//   height: 20px;
// `;

const LOADINGBUTTON = MuiStyled(LoadingButton)`
width: 100%;
height: 50px;
font-size: 14px;
border-radius: 10px;
margin-top: 6px;

&.MuiLoadingButton-loading {
  background-color: ${colors.btn_confirm};
}

& .MuiLoadingButton-loadingIndicator {
  color: ${colors.btn_confirm} !important;
}
`;

const COPYRIGHT = styled.div`
  margin-top: 12px;
  width: 100%;
  text-align: center;
  font: normal normal normal 12px/17px Noto Sans CJK KR;
  color: ${colors.num_999};
`;

const SingUpBox = styled.div`
  margin-top: 12px;
  margin-bottom: 20px;
  width: 100%;
  font: normal normal normal 12px/17px Noto Sans CJK KR;
  color: ${colors.num_999};
  text-align: center;

  span {
    color: ${colors.main};
    font-weight: bold;
    cursor: pointer;
  }
`;
