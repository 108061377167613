import React, { useEffect, useState } from 'react';
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import { useRecoilValue } from 'recoil';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { colors } from '../../Styles/ui';
import { userAtom } from '../../recoil/Auth/userAtom';
import LanguageDropBox from './Components/LanguageDropBox';
import AuthrityButton from './Components/AuthrityButton';
import UserInfoButton from './Components/UserInfoButton';
import MenuBox from './Components/MenuBox';

function HeaderContent() {
  return (
    <NavWrapper>
      {/* <MenuBox /> */}
      <LanguageDropBox />
      <Wall />
      {/* <AuthrityButton />
      <Wall /> */}
      <UserInfoButton />
    </NavWrapper>
  );
}

export default HeaderContent;
const NavCss = css`
  width: 100%;
  display: flex;
  align-items: center;
  list-style: none;
`;

const NavWrapper = styled.div`
  width: 100%;
  height: 77px;
  background-color: ${colors.bg_transparency};
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: flex-end;
  ${NavCss}

  .MuiButton-root {
    text-transform: none;
  }
`;

const Wall = styled.div`
  height: 30%;
  width: 1px;
  margin: 0px 12px;
  background-color: white;
`;
