import styled from '@emotion/styled';
import { Input } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { colors, spacing } from '../../../Styles/ui';

const MultiInputBox = ({
  id,
  type = 'text',
  value,
  onChange,
  readOnly,
}: {
  id: string;
  type?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
}) => {
  const { t } = useTranslation();
  const getPlaceholder = (id: string) => {
    return t(`sign.block.placeholder.${id}`);
  };
  return (
    <Wrapper>
      <Input
        disableUnderline
        fullWidth
        type={type}
        id={id}
        value={value}
        placeholder={getPlaceholder(id)}
        onChange={onChange}
        readOnly={readOnly}
        multiline
      />
    </Wrapper>
  );
};

export default MultiInputBox;

const Wrapper = styled.div`
  height: 230px;
  padding-top: 10px;

  .MuiInput-input {
    font: normal normal normal 15px/25px Noto Sans CJK KR;
    ${spacing.paddingX(5)};
    padding-top: 4px;
    padding-bottom: 5px;
    color: ${colors.text} !important;
    background-color: ${colors.num_333} !important;
    border-radius: 10px;
    height: 100% !important;
    display: flex;
    align-items: center;
  }

  .MuiInput-root {
    height: 100% !important;
  }
`;
