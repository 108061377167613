import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { color, styled as MuiStyled } from '@mui/system';
import { Avatar, Box, Button, Input, Link, Tab, Tabs, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { colors, spacing } from '../../Styles/ui';

const GoodByeDecakill = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <CONTENT>
        <SUBJECT>
          <a href='https://www.dailyesports.com/view.php?ud=202209151619137999a7594bcb2f_27'>
            DECAKILL Service Shutdown
          </a>
        </SUBJECT>
        <COPYRIGHT>Copyright Team Snowball All Rights Reserved.</COPYRIGHT>
      </CONTENT>
    </Wrapper>
  );
};

export default GoodByeDecakill;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #111111 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CONTENT = styled.div`
  width: 1216px;
  // height: 290px;
  background-image: url('/Images/bg-login.png');
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginBox = styled.div`
  background-color: ${colors.num_222};
  border-radius: 10px;

  .MuiTabs-flexContainer {
    border-bottom: solid 1px ${colors.num_444};
    width: 100%;
  }

  .MuiTab-root {
    // border-radius: 10px 10px 0px 0px;
    border: 1px solid ${colors.num_444};
    border-bottom-color: ${colors.num_222};
    color: ${colors.text};
    width: 50%;
    // margin: 0 3px;
    background-color: ${colors.num_111};
  }

  .MuiTabs-indicator {
    border: 1px solid ${colors.num_222};
    background-color: ${colors.num_222};
  }

  .Mui-selected {
    background-color: ${colors.num_222};
  }
`;

const FORM = styled.form<{ isSelectedTab: boolean }>`
  display: ${({ isSelectedTab }) => (isSelectedTab ? 'flex' : 'none')};
  width: 596px;
  padding: 46px 127px 47px;
  flex-direction: column;
  justify-content: center;

  &::-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
    box-shadow: 0 0 0 1000px white inset;
  }

  .MuiInput-input {
    font: normal normal normal 15px/25px Noto Sans CJK KR;
    ${spacing.paddingY(0)};
    height: 50px;
    ${spacing.paddingX(5)};
    ${spacing.marginB(0)};
    color: ${colors.text} !important;
    background-color: ${colors.num_333} !important;
    border-radius: 10px;
  }
`;

const SUBJECT = styled.div`
  margin-top: 42px;
  margin-bottom: 21px;
  width: 100%;
  text-align: center;
  color: ${colors.text};
  font: normal normal bold 24px/25px Poppins;
`;

// const ERRORTEXT = styled.p`
//   ${spacing.paddingY(2)}
//   color: ${colors.badge_red};
//   font-size: 11px;
//   height: 20px;
// `;

const LOADINGBUTTON = MuiStyled(LoadingButton)`
width: 100%;
height: 50px;
font-size: 14px;
border-radius: 10px;
margin-top: 12px;

&.MuiLoadingButton-loading {
  background-color: ${colors.btn_confirm};
}

& .MuiLoadingButton-loadingIndicator {
  color: ${colors.btn_confirm} !important;
}
`;

const COPYRIGHT = styled.div`
  width: 100%;
  text-align: center;
  font: normal normal normal 12px/17px Noto Sans CJK KR;
  color: ${colors.num_999};
  padding-bottom: 20px;
  margin-top: 22px;
`;

const TwitchLabel = styled.a`
  font: normal normal bold 15px/24px Noto Sans KR;
  white-space: nowrap;
  }
`;
