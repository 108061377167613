import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { colors, spacing } from '../../../../Styles/ui';

export const ModalHeader = styled.div`
  font: normal normal bold 18px/26px Noto Sans CJK KR;
  border-bottom: solid 1px ${colors.num_444};
  padding: 24px 40px;
  color: ${colors.text};
`;

export const CustomBodyCss = css`
  padding: 35px 40px;

  .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.text};
  }

  .MuiCustomLabel-shrink {
    background-color: ${colors.num_222};
  }

  .MuiSvgIcon-root {
    color: ${colors.text};
  }

  .MuiSelect-iconOpen {
    color: ${colors.main};
  }

  .MuiFormControl-root {
    margin: 0;
  }
`;

export const ModalBody = styled.div`
  ${CustomBodyCss}

  .MuiInput-input {
    font: normal normal normal 15px/25px Noto Sans CJK KR;
    // ${spacing.paddingY(3.25)};
    min-height: 40px !important;
    ${spacing.paddingX(2.5)};
    color: ${colors.text} !important;
    background-color: ${colors.num_333} !important;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
`;

export const ModalBodyInputPaddingZero = styled.div`
  ${CustomBodyCss}

  .MuiInput-input {
    font: normal normal normal 15px/25px Noto Sans CJK KR;
    min-height: 40px !important;
    color: ${colors.text} !important;
    background-color: ${colors.num_333} !important;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
`;

export const InputBox = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  margin: 16px 0;
`;

export const CustomLabel = styled.label`
  width: 100px;
  color: ${colors.text};
`;

export const CustomInput = styled.input`
  width: 70%;
  min-height: 50px;
  border-radius: 10px;
  color: ${colors.text};
  padding: 0 16px;
  font: normal normal normal 15px/21px Noto Sans CJK KR;
  background-color: ${colors.num_333};
`;

export const SelectedLabel = styled.label`
  color: ${colors.text};
  padding: 0 10px;
`;

export const PlaceholderLabel = styled.label`
  color: ${colors.num_888};
  padding: 0 10px;
`;

export const ButtonBox = styled.div`
  padding: 10px 0px;
  display: flex;
`;

export const modalButtonStyle = {
  width: '100%',
  height: '50px',
  margin: '0px 10px',
  borderRadius: '10px',
};
