import styled from '@emotion/styled/macro';
import { Button, SvgIcon } from '@mui/material';
import { useEffect, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userAtom, UserType } from '../../../recoil/Auth/userAtom';
import gameAtom from '../../../recoil/game/gameAtom';
import { colors } from '../../../Styles/ui';
import GameButton from '../Components/GameButton';
import SidebarButton from '../Components/MenuButton';

const GameBox = () => {
  const param = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const gameList = useRecoilValue(gameAtom.gameList);
  const { t } = useTranslation();

  const loc = location.pathname.split('/')[1];

  function goNavigate(pagePath: string) {
    return navigate(pagePath);
  }

  useEffect(() => {
    console.log('param.game', param.game);
    if (!param.game && gameList.length > 0) {
      console.log('location', location);
      goNavigate(`/${loc}/${gameList[0].key}`);
    }
  }, [gameList, param.game]);

  return (
    <GameWrapper>
      <SUBJECT>GAME</SUBJECT>

      {gameList.map((game) => {
        return (
          <Link to={`/${loc}/${game.key}`}>
            <GameButton text={game.key} />
          </Link>
        );
      })}
    </GameWrapper>
  );
};
export default GameBox;

const GameWrapper = styled.div`
  width: 100%;
  .MuiButton-root {
    justify-content: flex-start;
  }
`;

const SUBJECT = styled.div`
  font: normal normal normal 12px/25px Noto Sans CJK KR;
  color: ${colors.text};
  opacity: 0.5;
  margin-bottom: 11px;
`;
