import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import HomeContent from './Pages/Home/HomeContent';
import LoginContent from './Pages/Login/LoginContent';
import NotFound404 from './Pages/NotFound/NotFound404';
import { permissionsAtom, userAtom } from './recoil/Auth/userAtom';
import Layout from './Layout';
import CustomErrorController from './components/Error/CustomErrorController';
import SignComponent from './Pages/Sign/SignComponent';
import BlockAccount from './Pages/Sign/screen/BlockAccount';
import RewardContent from './Pages/Reward/RewardContent';
import AccountIndex from './Pages/Account/screens/AccountIndex';
import AccountContent from './Pages/Account/AccountContent';
import RewardIndex from './Pages/Reward/screens/RewardIndex';
import gameAtom from './recoil/game/gameAtom';
import GameServices from './services/GameServices';
import HomeIndex from './Pages/Home/screens/HomeIndex';
import RewardTabIndex from './Pages/Reward/screens/RewardTabIndex';
import RewardScheduleComponent from './Pages/Reward/components/RewardTable/Schedule/ScheduleTableComponent';
import GoodsTableComponent from './Pages/Reward/components/GoodsComponent';
import GoodByeDecakill from './Pages/Login/GoodByeDecakill';


function Routor() {
  const user = useRecoilValue(userAtom);
  const perAccount = useRecoilValue(permissionsAtom.account);
  document.title = `Decakill`;

  return (
    <Router>
      <CustomErrorController />

      <Routes>
        {/* <Route path='/' element={<PrivateOutlet />}>
          <Route element={<Layout />}>
            <Route path='/' element={<HomeContent />}>
              <Route index element={<HomeIndex />} />
            </Route>
            <Route path="reward" element={<RewardContent />}>
              <Route path=':game' element={<RewardIndex />}>
                <Route path=':league' element={<RewardTabIndex />}>
                  <Route index element={<RewardScheduleComponent />} />
                  <Route path=':schedule' element={<GoodsTableComponent />} />
                </Route>
              </Route>
            </Route>
            {perAccount ? <Route path="account" element={<AccountContent />}>
              <Route index element={<AccountIndex />} />
            </Route> : <></>}

          </Route>
        </Route> */}
        <Route path='/' element={<GoodByeDecakill />} />
        {/* <Route path='/sign' element={<SignComponent />}>
          <Route index element={<BlockAccount />} />
        </Route> */}
        <Route path='*' element={<NotFound404 />} />
      </Routes>
    </Router >
  );
}

export default Routor;

function PrivateOutlet() {
  // const location = useLocation();
  const user = useRecoilValue(userAtom);
  const setGame = useSetRecoilState(gameAtom.gameList)
  const setPerAccount = useSetRecoilState(permissionsAtom.account);
  const resetPerAccount = useResetRecoilState(permissionsAtom.account)

  const { mutate: getGameList } = useMutation(() => GameServices.getGameList(), {
    onSuccess: (data) => {

      const gaemSetList = [{
        idx: 0,
        key: "streamer",
        league: [{
          idx: 0,
          key: "streamer"
        }]
      }]

      data.game.forEach(game => {
        gaemSetList.push(game);
      })

      setGame(gaemSetList);
    },
  });

  useEffect(() => {
    getGameList()
  }, [])

  useEffect(() => {
    if (user) {
      const onlySupervisor = user.securityLevel === 1;
      const onlyManager = user.securityLevel === 2;
      const allUser = true;
      setPerAccount(onlySupervisor)
    } else {
      resetPerAccount();
    }
  }, [user])

  // const param = useParams();
  // return <Outlet />
  return user ? <Outlet /> : <Navigate to='/login' />;
}
