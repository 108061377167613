import styled from '@emotion/styled';
import { Input, InputBaseComponentProps, SxProps } from '@mui/material';
import ConvertSvgIcon from '../../../../../components/Ui/ConvertSvgIcon';
import { colors, spacing } from '../../../../../Styles/ui';

interface InputBoxParam {
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  name?: string;
  startimg?: string;
  endimg?: string;
  sx?: SxProps;
  readOnly?: boolean;
  inputProps?: InputBaseComponentProps;
}

export const InputBox = ({
  value,
  onChange,
  name = 'null',
  type = 'text',
  placeholder = '',
  startimg,
  endimg,
  sx,
  inputProps,
  readOnly,
}: InputBoxParam) => {
  return (
    <InputWrapper type={type}>
      {startimg ? <IconBox>{ConvertSvgIcon({ img: startimg })}</IconBox> : <></>}
      <Input
        disableUnderline
        value={value}
        type={type}
        name={name}
        autoComplete='off'
        onChange={onChange}
        placeholder={placeholder}
        sx={sx}
        inputProps={inputProps}
        readOnly={readOnly}
      />
      {endimg ? ConvertSvgIcon({ img: endimg }) : <></>}
    </InputWrapper>
  );
};

const IconBox = styled.div`
  padding: 0 10px;
`;

const InputWrapper = styled.div<{ type?: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${colors.text} !important;
  background-color: ${colors.num_333} !important;
  border-radius: 10px;

  .MuiInputBase-input {
    font: normal normal normal 15px/25px Noto Sans CJK KR;
    height: 40px;
    ${spacing.paddingX(5)};
    color: ${colors.text} !important;
    background-color: ${colors.num_333} !important;
    border-radius: 10px;
    ${({ type }) => type === 'number' && 'text-align: right;'}
  }

  .MuiInput-root {
    height: 100% !important;
    width: 100% !important;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const GoodsModalItemBox = styled.div`
  ${spacing.marginB(3)};
  width: 100%;
  margin-right: 10px;
`;
export const GoodsModalLabel = styled.div`
  width: auto;
  color: ${colors.text};
  font-size: 20px;
  margin-bottom: 10px;
`;
export const GoodsModalHeader = styled.div`
  padding-bottom: 30px;
`;
export const GoodsModalHeaderLabel = styled.label`
  color: ${colors.text};
  font-size: 24px;
  padding: 0 10px 5px;
  border-bottom: solid 3px ${colors.보라색[800]};
`;
