import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import { deplayDate, version } from '../../Pages/config';
import { colors } from '../../Styles/ui';
import GameBox from './screens/GameBox';
import MenuBox from './screens/MenuBox';
import UserBox from './screens/UserBox';

const SidebarContent = () => {
  const location = useLocation();
  return (
    <SidebarWrapper>
      <SUBJECT>KDA Master</SUBJECT>
      <UserBox />
      <MenuBox />
      {location.pathname.includes('/reward') ? <GameBox /> : <></>}
      <VersionBox>
        <div>Version: {version}</div>
        <div>Deployed: {deplayDate}</div>
      </VersionBox>
    </SidebarWrapper>
  );
};

export default SidebarContent;

const SidebarWrapper = styled.div`
  width: 270px;
  height: 100vh;
  background-color: ${colors.num_222};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
`;

const SUBJECT = styled.div`
  margin-top: 24px;
  color: ${colors.text};
  font: normal normal bold 20px/25px Poppins;
`;

const VersionBox = styled.div`
  height: 100%;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  color: ${colors.text_none};
`;
