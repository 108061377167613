import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { colors, spacing } from '../../../Styles/ui';
import CancelButton from '../components/CancelButton';
import InputBox from '../components/InputBox';
import MultiInputBox from '../components/MultiInputBox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  sx: {
    '&& .Mui-selected': {
      // backgroundColor: colors.main,
      // color: colors.text,
    },
  },
};

const BlockAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [csMail, setCsMail] = useState<string>('cs@teamsnowball.com');
  const [uid, setUid] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [alterText, setAlterText] = useState<string>('');
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    if (id === 'uid') {
      setUid(value);
    } else if (csMail === 'password') {
      setCsMail(value);
    } else if (id === 'email') {
      setEmail(value);
    } else if (id === 'comment') {
      setComment(value);
    }
  };

  const onClick = () => {
    if (uid.length === 0) {
      return setAlterText('아이디 없어');
    }
    if (email.length === 0) {
      return setAlterText('이메일 없어');
    }

    return navigate('/login');
  };

  return (
    <Wrapper>
      <HeaderBox>
        <div className='kdaMaster'>DECAKILL MASTER</div>
        <div className='desc'>{t('sign.block.desc1')}</div>
        <div className='desc'>{t('sign.block.desc2')}</div>
      </HeaderBox>
      <BodyBox>
        <InputBox id='csMail' value={csMail} onChange={onChangeHandler} readOnly />
        <InputBox id='email' value={email} onChange={onChangeHandler} />
        <InputBox id='uid' value={uid} onChange={onChangeHandler} />
        <MultiInputBox id='comment' value={comment} onChange={onChangeHandler} />

        <AlterDesc>{alterText}</AlterDesc>
        <ButtonBox>
          <CancelButton />
          <Button
            variant='contained'
            sx={{
              borderRadius: '10px',
              marginTop: '20px',
              padding: '15px 0 14px 0',
              font: 'normal normal bold 15px/25px Noto Sans CJK KR',
              marginLeft: '7px',
            }}
            onClick={onClick}
            fullWidth
          >
            {t('common.button.confirm')}
          </Button>
        </ButtonBox>
      </BodyBox>
    </Wrapper>
  );
};

export default BlockAccount;

const Wrapper = styled.div`
  width: 408px;
`;

const HeaderBox = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  color: ${colors.text};
  .kdaMaster {
    font: normal normal bold 20px/25px Poppins;
  }

  .desc {
    white-space: nowrap;
    font: normal normal bold 20px/29px Noto Sans CJK KR;
  }
`;

const BodyBox = styled.div`
  width: 100%;
  height: auto;

  border-radius: 8px;
  margin-top: 20px;
  padding: 6px 54px;
`;

const AlterDesc = styled.div`
  width: 100%;
  height: auto;
  color: red;
  font: normal normal normal 15px/21px Noto Sans CJK KR;
`;

const ButtonBox = styled.div`
  display: flex;
`;
