import { AxiosError, AxiosResponse } from 'axios';
import { ILoadAccountsList } from '../Pages/Account/screens/AccountIndex';
import { axiosDelete, axiosGet, axiosPost, axiosPut, IStatus } from './axios/axios';

export interface IAccountsParam {
  uid: string;
  password: string;
  securityLevel: number;
}

export interface IPutAccountsParam extends IAccountsParam {
  idx: number;
  block: number;
}

export interface IDeleteAccountsParam {
  idx: number;
  uid: string;
  
}

export interface IAccounts {
  idx: number;
  uid: string;
  password: string;
  exdate: string;
  name: string;
  securityLevel: number;
  block: number;
  withdrawDate: string;
  del: number;
}

interface IGetAccountsSuccess extends IStatus {
  count: number;
  accounts: IAccounts[];
}

interface IPutAccountsSuccess extends IStatus {
  updateTheme: IAccounts;
}

interface IPostAccountsSuccess extends IStatus {
  createTheme: IAccounts;
}

interface IDeleteAccountsSuccess extends IStatus {
  deleteAccountsCount: number;
}
interface IUnBlockAccountsSuccess extends IStatus {
  unblockAccountsCount: number;
}

class AccountsServices {
  getAccountsList(): Promise<IGetAccountsSuccess> {
    return new Promise((resolve, reject) => {
      axiosGet({
        url: '/accounts',
      })
        .then((res: AxiosResponse) => {
          console.log('res', res);
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          console.log('err', err);
          reject(err);
        });
    });
  }

  updateAccounts(data: IPutAccountsParam): Promise<IPutAccountsSuccess> {
    return new Promise((resolve, reject) => {
      axiosPut({
        url: '/accounts',
        data,
      })
        .then((res: AxiosResponse) => {
          console.log('res', res);
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          console.log('err', err);
          reject(err);
        });
    });
  }

  createAccounts(data: IAccountsParam): Promise<IPostAccountsSuccess> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/accounts',
        data,
      })
        .then((res: AxiosResponse) => {
          console.log('res', res);
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          console.log('err', err);
          reject(err);
        });
    });
  }

  deleteAccounts(data: IDeleteAccountsParam): Promise<IDeleteAccountsSuccess> {
    return new Promise((resolve, reject) => {
      axiosDelete({
        url: '/accounts',
        data,
      })
        .then((res: AxiosResponse) => {
          console.log('res', res);
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          console.log('err', err);
          reject(err);
        });
    });
  }

  unblockAccounts(data: IPutAccountsParam): Promise<IUnBlockAccountsSuccess> {
    return new Promise((resolve, reject) => {
      axiosPost({
        url: '/accounts/unblock',
        data,
      })
        .then((res: AxiosResponse) => {
          console.log('res', res);
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          console.log('err', err);
          reject(err);
        });
    });
  }
}

export default new AccountsServices();
