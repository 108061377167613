import { IconButton, Modal } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../../Styles/ui';
import { IconCss } from '../../../../lib/Ui/commonCss';
import AccountDeleteModalBox from '../Modal/AccountDeleteModalBox';
import { ILoadAccountsList } from '../../screens/AccountIndex';

interface IDeleteAccountButton {
  selectedUid: string;
  idx: number;
  loadAccountsList: ILoadAccountsList;
}

const DeleteAccountButton = ({ selectedUid, idx, loadAccountsList }: IDeleteAccountButton) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton
        onClick={() => {
          handleOpen();
        }}
      >
        <DeleteIcon sx={IconCss} fontSize='inherit' />
      </IconButton>
      <Modal open={open} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
        <AccountDeleteModalBox
          uid={selectedUid}
          idx={idx}
          handleClose={handleClose}
          loadAccountsList={loadAccountsList}
        />
      </Modal>
    </>
  );
};

export default DeleteAccountButton;

const Header = styled.div`
  font: normal normal bold 18px/26px Noto Sans CJK KR;
  // border-bottom: solid 1px ${colors.num_444};
  width: 100%;
  padding: 24px;
  text-align: center;
  color: ${colors.text};
`;

const Body = styled.div`
  color: ${colors.text};
  text-align: center;
  font: normal normal normal 15px/21px Noto Sans CJK KR;
`;

const ButtonBox = styled.div`
  padding: 30px;
  display: flex;
`;
