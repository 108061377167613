import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Box, Input, OutlinedInput } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from '@emotion/styled';
import { colors } from '../../../../Styles/ui';
import { PlaceholderLabel, SelectedLabel } from '../../../../components/Ui/Modal/lib/modal_styled';
import gameAtom from '../../../../recoil/game/gameAtom';
import { ISchedule } from '../../../../services/ScheduleServices';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  sx: {
    '&& .Mui-selected': {
      backgroundColor: colors.main,
      color: colors.text,
    },
  },
};

const MODAL_FORM_CONTROL_SX = {
  width: 250,
  color: colors.text,
  backgroundColor: colors.num_333,
  borderRadius: '5px',
  ':hover': {
    borderColor: colors.text,
    borderRadius: '5px',
  },
  margin: '10px 0',
};

const MODAL_SELECT_SX = {
  borderColor: colors.text,
  backgroundColor: colors.num_333,
  color: colors.text,
  borderRadius: '5px',
  ':hover': {
    borderColor: colors.text,
    borderRadius: '5px',
  },
};

export default function ScheduleListSelectBox({ scheduleList }: { scheduleList: ISchedule[] }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param = useParams();

  const [selectedFileName, setSelectedFileName] = useState<string>(param.schedule ?? 'None');

  const onChangeSelect = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    const { game, league, filename } = param;
    setSelectedFileName(value);
    navigate(`/reward/${game}/${league}/${value ?? 'None'}`);
  };

  useEffect(() => {
    // 뒤로/앞으로 가기 시, 페이지가 재랜더링 되지 않아서 Select 박스가 바뀌지 않아서 추가.
    setSelectedFileName(param.schedule ?? 'None');
  }, [param.schedule]);

  return (
    <FormControl sx={MODAL_FORM_CONTROL_SX}>
      <Select
        displayEmpty
        sx={MODAL_SELECT_SX}
        value={selectedFileName}
        label='File Name List'
        name='file-name-list'
        onChange={onChangeSelect}
        input={<Input disableUnderline sx={{ borderRadius: '10px !important', borderColor: 'white' }} />}
        MenuProps={MenuProps}
        renderValue={(selected) => {
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, color: colors.text }}>
              {selected ? (
                <SelectedLabel>{selected}</SelectedLabel>
              ) : (
                <PlaceholderLabel>{t('reward.placeholder.fileNameList')}</PlaceholderLabel>
              )}
            </Box>
          );
        }}
      >
        {scheduleList.map((goodsFileName) => (
          <MenuItem key={goodsFileName.idx} value={goodsFileName.name}>
            {goodsFileName.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const ExhibitionCountLabel = styled.div``;
