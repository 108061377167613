import styled from '@emotion/styled';
import { Button, Modal } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../../Styles/ui';
import CreateGoodsModalBox from '../Modal/CreateGoodsModalBox';
import { ILoadGoodsList } from './GoodsItemListBox';
import { goodsBoxCss } from './lib/goods_css';

interface IGoodsCreateBoxParam {
  scheduleIdx: number;
  loadGoodsList: ILoadGoodsList;
}

const GoodsCreateBox = ({ scheduleIdx, loadGoodsList }: IGoodsCreateBoxParam) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return scheduleIdx ? (
    <Wrapper>
      <Button
        variant='contained'
        color='primary'
        sx={{ height: 'fit-content' }}
        onClick={() => {
          handleOpen();
        }}
      >
        {t('reward.button.createGoods')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <CreateGoodsModalBox
          scheduleIdx={scheduleIdx}
          handleClose={handleClose}
          loadGoodsList={loadGoodsList}
        />
      </Modal>
    </Wrapper>
  ) : (
    <></>
  );
};

export default GoodsCreateBox;

const Wrapper = styled.div`
  ${goodsBoxCss}
  background-color: ${colors.num_333};
  display: flex;
  justify-content: center;
  align-items: center;
`;
