import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { Box, Stack } from '@mui/material';
import GoodsServices, {
  IGetGoodsListParam,
  IGoods,
  IUpdateGoodsOrderParam,
} from '../../../../services/GoodsServices';
import { ISchedule } from '../../../../services/ScheduleServices';
import WebGoods from '../WebGoods';
import GoodsCreateBox from './GoodsCreateBox';
import { DRAG_STATE_MAP } from '../../lib/reward_map';

export type ILoadGoodsList = () => void;

const GoodsItemListBox = ({ scheduleList }: { scheduleList: ISchedule[] }) => {
  const param = useParams();
  const [goodsList, setGoodsList] = useState<IGoods[]>([]);
  const [scheduleIdx, setScheduleIdx] = useState<number>();
  const [items, setItems] = useState<{ id: any }[]>([]);
  const [dragState, setDragState] = useState<string>(DRAG_STATE_MAP.end);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const { mutate: getGoodsList, isLoading } = useMutation(
    (data: IGetGoodsListParam) => GoodsServices.getGoodsList(data),
    {
      onSuccess: (data) => {
        setGoodsList(data.goods);
      },
    },
  );

  const { mutate: updateGoodsOrder } = useMutation(
    (data: IUpdateGoodsOrderParam) => GoodsServices.updateGoodsOrder(data),
    {
      onSuccess: (data) => {
        // loadGoodsList();
      },
      onError: () => {
        // 실패하면 굿즈 리스트를 다시 불러옴
        loadGoodsList();
      },
    },
  );

  const loadGoodsList: ILoadGoodsList = () => {
    const { schedule } = param;
    if (schedule && schedule !== 'None' && scheduleList.length > 0) {
      const { idx } = scheduleList.filter((data) => data.name === param.schedule)[0];
      setScheduleIdx(idx);
      getGoodsList({ scheduleIdx: idx });
    } else {
      setScheduleIdx(NaN);
      setGoodsList([]);
    }
  };

  useEffect(() => {
    loadGoodsList();
  }, [param.schedule, scheduleList]);

  useEffect(() => {
    if (goodsList.length > 0) {
      const itemIdx = goodsList.map((goods) => {
        return { id: goods.idx };
      });
      setItems(itemIdx);
    } else {
      setItems([]);
    }
  }, [goodsList]);

  function handleDragEnd(event: DragEndEvent): void {
    const { active, over } = event;
    setDragState(DRAG_STATE_MAP.end);
    if (over) {
      if (active.id !== over.id) {
        const oldIndex = items.findIndex(({ id }) => id === active.id);
        const newIndex = items.findIndex(({ id }) => id === over.id);
        const result = arrayMove(items, oldIndex, newIndex);
        setItems(result);

        const goodsIdxList = result.map((data) => data.id);
        console.log(goodsList);
        setGoodsList((goodsList) => {
          return goodsIdxList.map((idx) => {
            return goodsList.filter((goods) => goods.idx === idx)[0];
          });
        });
        if (scheduleIdx) {
          updateGoodsOrder({ scheduleIdx, goodsIdxList });
        }
      }
    }
  }

  function handleDragStart(event: DragStartEvent): void {
    const { active } = event;
    setDragState(DRAG_STATE_MAP.start);
  }

  return (
    <>
      <Wrapper>
        {/* {goodsList.map((goods) => {
          return <GoodsItemBox goods={goods} loadGoodsList={loadGoodsList} />;
        })} */}
        <Box sx={{ marginTop: '40px', mb: 16, width: '100%' }}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={(event: DragEndEvent) => handleDragEnd(event)}
            onDragStart={(event: DragStartEvent) => handleDragStart(event)}
          >
            <SortableContext items={items.map(({ id }) => id)} strategy={rectSortingStrategy}>
              <Grid>
                {scheduleIdx &&
                  goodsList.map((goods: IGoods) => {
                    return (
                      <WebGoods
                        key={goods.idx}
                        // id={`${goods.idx}`}
                        goods={goods}
                        scheduleIdx={scheduleIdx}
                        loadGoodsList={loadGoodsList}
                        dragState={dragState}
                      />
                    );
                  })}
                {scheduleIdx && <GoodsCreateBox scheduleIdx={scheduleIdx} loadGoodsList={loadGoodsList} />}
              </Grid>
            </SortableContext>
          </DndContext>
        </Box>
      </Wrapper>
    </>
  );
};

export default GoodsItemListBox;

function Grid({ children }: { children: React.ReactNode }) {
  return (
    <div
      style={{
        display: 'inline-grid',
        gridTemplateColumns: 'auto auto auto auto',
        gridGap: 10,
        listStyle: 'none',
      }}
    >
      {children}
    </div>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
