import styled from '@emotion/styled/macro';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { permissionsAtom } from '../../../recoil/Auth/userAtom';
import { colors } from '../../../Styles/ui';
import MenuButton from '../Components/MenuButton';

const menuList = ['', 'reward', 'account'];

const MenuBox = () => {
  const param = useParams();
  const navigate = useNavigate();
  const perAccount = useRecoilValue(permissionsAtom.account);

  function goNavigate(pagePath: string) {
    return navigate(pagePath);
  }

  return (
    <MenuWrapper>
      <SUBJECT>MENU</SUBJECT>
      {menuList.map((menu: string, idx: number) => {
        if (menu === 'account' && !perAccount) {
          return <></>;
        }
        return (
          <Link to={`/${menu}`}>
            <MenuButton text={menu || 'home'} />
          </Link>
        );
      })}
      {/* <SidebarButton text='refresh' type='refresh' onClick={getInit} />  */}
    </MenuWrapper>
  );
};
export default MenuBox;

const MenuWrapper = styled.div`
  margin-top: 30px;
  .MuiButton-root {
    justify-content: flex-start;
  }
`;

const SUBJECT = styled.div`
  font: normal normal normal 12px/25px Noto Sans CJK KR;
  color: ${colors.text};
  opacity: 0.5;
  margin-bottom: 11px;
`;
