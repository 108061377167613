import { Modal } from '@mui/material';
import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { userAtom } from '../../recoil/Auth/userAtom';
import { errorAtom } from '../../recoil/errors/errorsAtom';
import { ApiError } from '../../services/axios/axios';
import AlterModalBox from '../Ui/Modal/AlterModalBox';

const _blockCode = [51, 53];

// 번역처리
const CustomErrorController = () => {
  const setUser = useResetRecoilState(userAtom);
  const [error, setError] = useRecoilState(errorAtom);
  const resetError = useResetRecoilState(errorAtom);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (func?: () => void) => {
    resetError();
    setOpen(false);
    if (error?.status.error_code) {
      const errCode = error?.status.error_code;
      if (_blockCode.includes(errCode)) {
        navigate('/sign');
      }
    }
  };

  useEffect(() => {
    if (!error) return;
    console.log(`에러코드: ${error.status.status_code}.${error.status.error_code}`);
    console.log(error);
    handleOpen();
  }, [error]);

  const getHeaderText = () => {
    if (error) {
      const { status_code: statusCode, error_code: errCode } = error.status;
      if (errCode) {
        return t(`axios.title.${statusCode}.${errCode}`);
      }
      return t(`axios.title.${statusCode}`);
    }
    return '';
  };

  const getContentText = () => {
    if (error) {
      const { status_code: statusCode, error_code: errCode, blockCountData } = error.status;
      if (errCode) {
        let content = t(`axios.desc.${statusCode}.${errCode}`);
        if (blockCountData) {
          content = content.replace('@', blockCountData.blockCount.toString());
        }
        return content;
      }
      return t(`axios.desc.${statusCode}`);
    }
    return '';
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <AlterModalBox
        headerText={getHeaderText()}
        contentText={getContentText()}
        CancelHandler={() => handleClose()}
      />
    </Modal>
  );
};

export default CustomErrorController;
