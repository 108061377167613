import styled from '@emotion/styled';
import { Button, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { colors } from '../../../Styles/ui';
import { rewardTabList } from '../lib/reward_map';

interface IRewardTableTabParam {
  handleTabChange: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const RewardTableTab = ({ handleTabChange }: IRewardTableTabParam) => {
  return (
    <Wrapper>
      {rewardTabList.map((name) => {
        return (
          <Button sx={{ color: colors.text }} name={name} value={name} onClick={handleTabChange}>
            {name}
          </Button>
        );
      })}
    </Wrapper>
  );
};

export default RewardTableTab;

const Wrapper = styled.div`
  width: 100%;
  color: ${colors.text};
  padding: 20px 0;
`;
