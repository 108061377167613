import styled from '@emotion/styled/macro';
import { colors, spacing } from '../../../../Styles/ui';
import { modalButtonStyle } from './modal_styled';

export const MODAL_STYLED = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: colors.num_222,
  border: `2px solid ${colors.num_444}`,
  borderRadius: '10px',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MODAL_MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  sx: {
    '&& .Mui-selected': {
      backgroundColor: colors.main,
      color: colors.text,
    },
  },
};

export const MODAL_FORM_CONTROL_SX = {
  width: '70%',
  color: colors.text,
  ':hover': {
    borderColor: colors.text,
  },
  margin: '10px 0',
};

export const MODAL_SELECT_SX = {
  borderColor: colors.text,
  color: colors.text,
  ':hover': {
    borderColor: colors.text,
  },
};

export const MODAL_CANCEL_BUTTON_SX = {
  ...modalButtonStyle,
  backgroundColor: colors.num_444,
  ':hover': {
    backgroundColor: colors.num_888,
  },
};
