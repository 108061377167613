/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import gameAtom from '../../recoil/game/gameAtom';
import { colors } from '../../Styles/ui';

function Home() {
  const gameList = useRecoilValue(gameAtom.gameList);
  const param = useParams();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (gameList.length > 0 && !param.game) {
  //     navigate(gameList[0].key);
  //   }
  // }, [gameList]);

  return <HomeWrapper>{gameList ? <Outlet /> : <></>}</HomeWrapper>;
}

export default Home;

const HomeWrapper = styled.div`
  width: 100%;
  // max-width: 1920px;
  min-width: 720px;
  height: auto;
  padding: 0px 40px;
  color: ${colors.text};
`;
