import styled from '@emotion/styled';
import { Input } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { colors, spacing } from '../../../Styles/ui';

const InputBox = ({
  id,
  type = 'text',
  value,
  onChange,
  readOnly,
  multiline,
}: {
  id: string;
  type?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  multiline?: boolean;
}) => {
  const { t } = useTranslation();
  const getPlaceholder = (id: string) => {
    return t(`sign.block.placeholder.${id}`);
  };
  return (
    <Wrapper>
      <Input
        disableUnderline
        fullWidth
        type={type}
        // className='id'
        id={id}
        value={value}
        placeholder={getPlaceholder(id)}
        autoComplete={id === 'password' ? 'new-password' : 'off'}
        onChange={onChange}
        readOnly={readOnly}
      />
    </Wrapper>
  );
};

export default InputBox;

const Wrapper = styled.div`
  padding-top: 4px;
  padding-bottom: 5px;
  .MuiInput-input {
    font: normal normal normal 15px/25px Noto Sans CJK KR;
    ${spacing.paddingX(5)};
    color: ${colors.text} !important;
    background-color: ${colors.num_333} !important;
    border-radius: 10px;
    height: 40px !important;
    display: flex;
    align-items: center;
  }
`;
