import styled from '@emotion/styled/macro';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../../recoil/Auth/userAtom';
import { colors } from '../../../Styles/ui';
import { ManageType } from '../../../Pages/Account/lib/dataset/accountDataset';

const getAccountGrade = (idx: number) => {
  if (typeof idx !== 'number') return '';
  let result = '';
  ManageType.forEach((grade, i) => {
    if (idx === i + 1) {
      result = grade;
    }
  });
  return result;
};

const UserBox = () => {
  const user = useRecoilValue(userAtom);
  console.log(user);
  return (
    <UserBoxWrapper>
      <ProfileImg src='/Images/no-profile.png' />
      <Position>{getAccountGrade(user?.securityLevel ?? 0)}</Position>
      <Email>{user?.uid}</Email>
    </UserBoxWrapper>
  );
};

export default UserBox;

const UserBoxWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfileImg = styled.img`
  max-width: 63px;
  max-height: 63px;
`;

const Position = styled.div`
  margin-top: 14px;
  color: ${colors.num_ccc};
  font: normal normal bold 15px/21px Noto Sans CJK KR;
`;

const Email = styled.div`
  margin-top: 3px;
  color: ${colors.num_888};
  font: normal normal normal 13px/19px Noto Sans CJK KR;
`;
